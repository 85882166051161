import { useContext, useState } from "react";
import { UserProfileContext } from "../contexts/user-profile-context";
import { setPoojaList } from "../services/local-storage";
import { ModalContext } from "../contexts/modal-context";
import { ToastContext } from "../contexts/toaster-context";
import { useTranslation } from "react-i18next";
import {
  POOJA_NAME_PLACEHOLDER_TEXT,
  POOJA_NAME_TEXT,
  SUBMIT,
} from "../constants/transaltion-keys";

const PoojaAddModal = () => {
  const [state, setState] = useState({ poojaName: "" });
  const { userProfileState, setUserProfileState } =
    useContext(UserProfileContext);
  const { setModalState } = useContext(ModalContext);
  const { addToast } = useContext(ToastContext);
  const { t } = useTranslation();

  const onInputChange = (event) => {
    setState((prev) => {
      return { ...prev, [event.target.name]: event.target.value };
    });
  };

  const onAddPoojaClick = () => {
    const poojaList = [
      ...(userProfileState?.poojaList || []),
      {
        id: +new Date(),
        name: state.poojaName,
      },
    ];
    setPoojaList(poojaList);
    setUserProfileState((prev) => ({ ...prev, poojaList }));
    setModalState((prev) => ({ ...prev, isOpen: false }));
    addToast({
      id: +new Date(),
      body: `${state.poojaName} added to pooja list.`,
    });
  };

  return (
    <form>
      <div className="form-group">
        <label className="mb-2" for="addPoojaNameText">
          {t(POOJA_NAME_TEXT)}
        </label>
        <input
          type="text"
          className="form-control"
          id="addPoojaNameText"
          placeholder={t(POOJA_NAME_PLACEHOLDER_TEXT)}
          name="poojaName"
          value={state.poojaName}
          onChange={onInputChange}
        />
      </div>
      <button
        type="button"
        className="btn btn-primary mt-2"
        onClick={onAddPoojaClick}
      >
        {t(SUBMIT)}
      </button>
    </form>
  );
};

export default PoojaAddModal;
