import { createContext, useState } from "react";

const DEFAULT_VALUES = {
  isOpen: false,
  title: "",
  bodyFunc: () => {},
  clsBtnText: "Close",
  confirmBtnText: "OK",
  closeClickHandler: () => {},
  confirmClickHandler: () => {},
};

export const ModalContext = createContext({});

function ModalContextProvider({ children }) {
  const [modalState, setModalState] = useState(DEFAULT_VALUES);
  return (
    <ModalContext.Provider
      value={{
        modalState,
        setModalState,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
}

export default ModalContextProvider;
