import { useContext, useEffect } from "react";
import { UserProfileContext } from "../contexts/user-profile-context";
import { GOODS_LIST } from "../stubs/goods";
import GoodsItem from "./goods-items/goods-item";
import SelectBox from "./select";
import PoojaViewModal from "./pooja-view-modal";
import { ModalContext } from "../contexts/modal-context";
import PoojaAddModal from "./pooja-add-modal";
import { useTranslation } from "react-i18next";
import { ADD_POOJA_TEXT, ETC_TEXT, GOODS_LIST_KEY } from "../constants/transaltion-keys";

function Goods() {
  const goodsList = GOODS_LIST;
  const { userProfileState, setUserProfileState } =
    useContext(UserProfileContext);
  const { setModalState } = useContext(ModalContext);
  const { t } = useTranslation();

  const poojaListChange = (event) => {
    setUserProfileState((prev) => {
      return { ...prev, selectedPooja: event.target.value };
    });
  };

  const onViewPoojaClick = () => {
    let selectedPooja = userProfileState?.poojaList.find(
      (pooja) => pooja.id === Number(userProfileState.selectedPooja)
    );
    setModalState((prev) => {
      return {
        ...prev,
        isOpen: true,
        title: selectedPooja?.name,
        bodyFunc: null,
        hideFooter: true,
        body: <PoojaViewModal pooja={selectedPooja} />,
      };
    });
  };

  const onAddPoojaClick = () => {
    setModalState((prev) => {
      return {
        ...prev,
        isOpen: true,
        title: t(ADD_POOJA_TEXT),
        bodyFunc: null,
        hideFooter: true,
        body: <PoojaAddModal />,
      };
    });
  };

  return (
    <section className="products py-5">
      <div className="container">
        <div className="row">
          <div className="col">
            <h2>{t(GOODS_LIST_KEY)}</h2>
          </div>
          <div className="col-md-9 d-flex mb-2">
            <div className="col">
              <SelectBox
                id={"samansuchipoojaselect"}
                value={userProfileState.selectedPooja}
                valueKey={"id"}
                labelKey={"name"}
                options={userProfileState?.poojaList || []}
                onChange={poojaListChange}
              />
            </div>
            <div className="col">
              <button
                type="button"
                className="btn btn-outline-primary mx-2"
                onClick={onViewPoojaClick}
              >
                <i className="fa-solid fa-eye" />
              </button>
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={onAddPoojaClick}
              >
                <i className="fa-solid fa-plus"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="row column-gap-2 row-cols-3">
          {goodsList.map((goods) => (
            <GoodsItem key={goods.id} goodItem={goods} />
          ))}
        </div>
        <br />
        <div>{t(ETC_TEXT)}...</div>
      </div>
    </section>
  );
}

export default Goods;
