import { useContext, useEffect, useRef } from "react";
import { ToastContext } from "../../contexts/toaster-context";

const Toast = ({ toast }) => {
  const { variant = "info", autoHide = true, delay = 15000 } = toast;
  const { removeToast } = useContext(ToastContext);
  const toastRef = useRef(null);

  useEffect(() => {
    const toastElement = toastRef.current;
    toastElement.classList.add("show");
    if (autoHide) {
      const timer = setTimeout(() => {
        toastElement.classList.remove("show");
        setTimeout(() => removeToast(toast.id), 300);
      }, delay);
      return () => clearTimeout(timer);
    }
  }, [autoHide, delay, removeToast, toast.id]);

  return (
    <div
      ref={toastRef}
      className={`toast mb-1 ${variant}`}
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      data-bs-autohide={autoHide.toString()}
      data-animation="true"
    >
      {toast.header && (
        <div className="toast-header">
          <strong className="me-auto">{toast.header}</strong>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="toast"
            aria-label="Close"
          ></button>
        </div>
      )}
      <div className="toast-body d-flex justify-content-between">
        {toast.body}
        {!toast.header && (
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="toast"
            aria-label="Close"
          ></button>
        )}
      </div>
    </div>
  );
};

export default Toast;
