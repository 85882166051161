import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
import ModalContextProvider from "./contexts/modal-context";
import UserProfileContextProvider from "./contexts/user-profile-context";
import { ROUTES } from "./routes";
import ToastContextProvider from "./contexts/toaster-context";
import "./i18n"

function App() {
  const router = createBrowserRouter(ROUTES);
  return (
    <>
      <UserProfileContextProvider>
        <ToastContextProvider>
          <ModalContextProvider>
            <RouterProvider router={router} />
          </ModalContextProvider>
        </ToastContextProvider>
      </UserProfileContextProvider>
    </>
  );
}

export default App;
