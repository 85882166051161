import { createContext, useState } from "react";

const DEFAULT_VALUES = {
  poojaList: [],
};

export const UserProfileContext = createContext({});

function UserProfileContextProvider({ children }) {
  const [userProfileState, setUserProfileState] = useState(DEFAULT_VALUES);
  return (
    <UserProfileContext.Provider
      value={{
        userProfileState,
        setUserProfileState,
      }}
    >
      {children}
    </UserProfileContext.Provider>
  );
}

export default UserProfileContextProvider;
