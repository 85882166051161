import { useContext } from "react";
import { ToastContext } from "../../contexts/toaster-context";
import Toast from "./toast";

const ToastContainer = () => {
  const { toasts } = useContext(ToastContext);

  return (
    <div className="toast-container position-fixed top-0 end-0 p-3">
      {toasts?.map((toast) => (
        <Toast key={toast.id} toast={toast} />
      ))}
    </div>
  );
};

export default ToastContainer;
