import { LANGUAGE, POOJALIST } from "../constants/local-storage-const";

export function setPoojaList(poojaList) {
  localStorage.setItem(POOJALIST, JSON.stringify(poojaList || []));
}

export function getPoojaList() {
  return JSON.parse(localStorage.getItem(POOJALIST));
}

export function setLanguage(lngCode) {
  localStorage.setItem(LANGUAGE, lngCode);
}

export function getLanguage() {
  return localStorage.getItem(LANGUAGE);
}
