import i18next from "i18next";
import { HINDI_CODE } from "../../constants/language";
import { UserProfileContext } from "../../contexts/user-profile-context";
import {
  getLanguage,
  getPoojaList,
  setLanguage,
  setPoojaList,
} from "../../services/local-storage";
import { useContext, useEffect } from "react";

function useLayout() {
  const { setUserProfileState } = useContext(UserProfileContext);

  const setPooja = () => {
    let poojaList = getPoojaList();
    if (!poojaList?.length) {
      poojaList = [{ id: +new Date(), name: "पूर्व निर्धारित" }];
      setPoojaList(poojaList);
    }
    setUserProfileState((prev) => ({
      ...prev,
      poojaList,
      selectedPooja: poojaList?.[0]?.id,
    }));
  };

  const setUserLanguage = () => {
    let lang = getLanguage();
    if (!lang) {
      lang = HINDI_CODE;
      setLanguage(lang);
    }
    i18next.changeLanguage(lang);
    setUserProfileState((prev) => ({
      ...prev,
      language: lang,
    }));
  };

  useEffect(() => {
    setPooja();
    setUserLanguage();
  }, []);
}

export default useLayout;
