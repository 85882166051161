import { Outlet, ScrollRestoration } from "react-router-dom";
import Footer from "../footer";
import Header from "../header";
import Modal from "../modal/modal";
import useLayout from "./useLayout";
import ToastContainer from "../toaster/toast-container";

function Layout() {
  useLayout();
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
      <Modal />
      <ToastContainer />
      <ScrollRestoration/>
    </>
  );
}

export default Layout;
