export const HEADER_TITLE = "header_title";
export const NAME = "name";
export const GANESH_SALUTATION = "ganesh_salutation";
export const CART = "cart";
export const VED_YAJYA_GOD_HAPPINESS = "ved_yajya_god_hapiness";
export const VED_INSPIRATION_POOJA = "ved_inspiration_pooja";
export const VED_YAJYA_WORK = "ved_yajya_work";
export const POOJA_GOODS_USE = "pooja_goods_use";
export const GOODS_LIST_KEY = "goods_list";
export const YAJNA_OUR_PARTICIPATION = "yajya_our_participation";
export const CONTACT_TEXT = "contact_text";
export const MOBILE_NUMBER = "mobile_number";
export const EMAIL = "email";
export const ADD_POOJA_TEXT = "add_pooja_text";
export const POOJA_NAME_TEXT = "pooja_name_text";
export const POOJA_NAME_PLACEHOLDER_TEXT = "pooja_name_placeholder_text";
export const SUBMIT = "submit";
export const ORDER_TEXT = "order_text";
export const NO_ITEMS_YET_TEXT = "no_items_yet_text";
export const CONTACT_TO_ORDER_TEXT = "contact_to_order_text";
export const POOJA_VED_HINDUISM_TEXT = "pooja_ved_hinduism_text";
export const ETC_TEXT = "etc";
