import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { UserProfileContext } from "../contexts/user-profile-context";
import { setLanguage } from "../services/local-storage";
import SelectBox from "./select";
import { CART, GANESH_SALUTATION, NAME } from "../constants/transaltion-keys";

const LANGUAGES = [
  {
    code: "en",
    name: "English",
  },
  {
    code: "hi",
    name: "हिंदी",
  },
];

function Header() {
  const navigate = useNavigate();
  const { userProfileState, setUserProfileState } =
    useContext(UserProfileContext);
  const { t, i18n } = useTranslation();
  const onCartClick = () => {
    navigate("/cart");
  };

  const onTitleClick = (event) => {
    event.preventDefault();
    navigate("/");
  };

  const onLanguageChange = (event) => {
    const lang = event.target.value;
    setUserProfileState((prev) => ({
      ...prev,
      language: lang,
    }));
    setLanguage(lang);
    i18n.changeLanguage(lang);
  };

  return (
    <>
      <nav className="navbar sticky-top navbar-expand-lg navbar-light bg-light border-bottom">
        <div className="col-md-3 col-6">
          <div
            className="container-fluid flex-grow-1"
            style={{ flexWrap: "nowrap", flex: 1 }}
          >
            <a className="navbar-brand" href="#" onClick={onTitleClick}>
              {t(NAME)}
            </a>
          </div>
        </div>
        <div className="col-md-7 col-6">
          <a
            className="d-flex justify-content-center"
            style={{ flex: 1, textDecoration: "none", color: "inherit" }}
          >
            {t(GANESH_SALUTATION)}
          </a>
        </div>
        <div className="col-md-1 col-6">
          <SelectBox
            id={"languageSelect"}
            value={userProfileState.language}
            valueKey={"code"}
            labelKey={"name"}
            options={LANGUAGES || []}
            onChange={onLanguageChange}
            selectCss="text-center"
          />
        </div>
        <div className="col-md-1 text-end col-6 my-2">
          <button
            type="button"
            className="btn btn-primary me-3"
            onClick={onCartClick}
          >
            <i className="fa-solid fa-cart-shopping"></i> {t(CART)}
            {/* <span className="badge text-bg-secondary ms-1">4</span> */}
          </button>
        </div>
      </nav>
    </>
  );
}

export default Header;
