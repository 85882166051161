import { Navigate } from "react-router-dom";
import Layout from "./components/Layout/layout";
import Cart from "./views/cart";
import Home from "./views/home";

export const ROUTES = [
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "/cart",
        element: <Cart />,
      },
      {
        path:"*",
        element: <Navigate to="/" replace />
      }
    ],
  },
];
