import { createContext, useState } from "react";

export const ToastContext = createContext({});

function ToastContextProvider({ children }) {
  const [toasts, setToasts] = useState([]);

  const addToast = (toast) => {
    setToasts((prevToasts) => [...prevToasts, toast]);
  };

  const removeToast = (id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };
  
  return (
    <ToastContext.Provider
      value={{
        toasts,
        addToast,
        removeToast
      }}
    >
      {children}
    </ToastContext.Provider>
  );
}

export default ToastContextProvider;
