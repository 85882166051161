import { useContext } from "react";
import { setPoojaList } from "../services/local-storage";
import { UserProfileContext } from "../contexts/user-profile-context";
import { RUPEE } from "../constants/unicode-code-const";

const PoojaViewModal = ({ pooja }) => {
  const { userProfileState, setUserProfileState } =
    useContext(UserProfileContext);
  const onDeleteClick = (pooja, item) => {
    pooja.items = pooja.items.filter(
      (it) => it.sel_item_id != item.sel_item_id
    );
    setPoojaList(userProfileState?.poojaList);
    setUserProfileState((prev) => {
      return { ...prev };
    });
  };

  const getOptions = (item) => {
    return Object.keys(item?.options || {})
      ?.reduce((acc, option) => {
        if (item[option]) {
          if (option === "price") {
            acc.push(`${RUPEE}${item[option]}`);
          } else {
            acc.push(item[option]);
          }
        }
        return acc;
      }, [])
      ?.join("|");
  };

  return (
    <>
      {pooja?.items?.length ? (
        <ol className="list-group list-group-numbered">
          {pooja?.items?.map((item) => {
            return (
              <li className="list-group-item d-flex border-0">
                <div className="d-flex justify-content-between align-items-center flex-grow-1">
                  <div className="col-md-3">
                    <span>{item?.title}</span>
                  </div>
                  <div className="col-md-3 text-center">{getOptions(item)}</div>
                  <div className="col-md-3 text-center">
                    <i
                      className="fa-solid fa-trash text-danger cursor-pointer"
                      onClick={onDeleteClick.bind(null, pooja, item)}
                    ></i>
                  </div>
                </div>
              </li>
            );
          })}
        </ol>
      ) : (
        "No items yet."
      )}
    </>
  );
};

export default PoojaViewModal;
