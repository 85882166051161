const SelectBox = ({
  id,
  options,
  valueKey,
  labelKey,
  value,
  onChange,
  label,
  selectCss=""
}) => {
  return (
    <div className="form-group">
      {label && <label htmlFor="select">{label}</label>}
      <select
        className={`form-control ${selectCss}`}
        id={id}
        value={value}
        onChange={onChange}
      >
        {options?.map((option) => (
          <option key={option?.[valueKey]} value={option?.[valueKey]}>
            {option?.[labelKey]}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectBox;
