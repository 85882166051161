import {
  BUNDLE,
  DOZEN,
  GM,
  MILLI_LITER,
  PACKET,
  PCS,
} from "../constants/goods-units";

export const GOODS_LIST = [
  {
    id: 1,
    img_path: "https://drive.google.com/thumbnail?id=1qtpSNzGqjsZz5FRosansvM21ErZ4zquI",
    title: "चुनरी (Chunari)",
    options: {
      price: [20],
    },
    unit: PCS,
    unit_quantity: 1,
  },
  {
    id: 2,
    img_path: "https://drive.google.com/thumbnail?id=1RpilZPIiq1-E9X23riWnGFxpT1fdHRdC",
    title: "चुनरी (Chunari)",
    options: {
      price: [25],
    },
    unit: PCS,
    unit_quantity: 1,
  },
  {
    id: 3,
    img_path: "https://drive.google.com/thumbnail?id=1shv8ypSF0xQQS2oFtAIPD151r1oaJQBj",
    title: "चुनरी (Chunari)",
    options: {
      price: [35],
    },
    unit: PCS,
    unit_quantity: 1,
  },
  {
    id: 4,
    img_path: "https://drive.google.com/thumbnail?id=1d5t38Xb4xyCuPeMCf1yAm8gzZNsuu2Dq",
    title: "राम ध्वज लकड़ी के साथ  (Ram Flag With Stick)",
    options: {
      price: [80],
    },
    unit: PCS,
    unit_quantity: 1,
  },
  {
    id: 5,
    img_path: "https://drive.google.com/thumbnail?id=1U8KCo8UT58SvB1yGSsRN2d1Mf9A-I9qe",
    title: "जय श्री राम पताका (Jai Shri Ram Pataka)",
    options: {
      price: [200],
    },
    unit: PACKET,
    unit_quantity: 1,
  },
  {
    id: 6,
    img_path: "https://drive.google.com/thumbnail?id=1kUG7B-fEqnZz-AQIZBEfbqRsHLY--Vvg",
    title: "ॐ  पताका  (Om Pataka)",
    options: {
      price: [180],
    },
    unit: PACKET,
    unit_quantity: 1,
  },
  {
    id: 7,
    img_path: "/images/incens-sticks.avif",
    title: "अगरबत्ती (Incense Stick)",
    options: {
      price: [20, 30],
    },
    unit: PACKET,
    unit_quantity: 1,
  },
  {
    id: 8,
    img_path: "https://drive.google.com/thumbnail?id=1QDYRTfkAPcIvGX6l4gdjXihcgAtnc0fp",
    title: "मिट्टी का दीया (Oil Lamps)",
    options: {
      price: [25],
    },
    unit: DOZEN,
    unit_quantity: 1,
  },
  {
    id: 9,
    img_path: "/images/basil leaves.avif",
    title: "तुलसी के पत्ते (Basil Leaves))",
    options: {
      price: [10],
    },
    unit: BUNDLE,
    unit_quantity: 1,
  },
  {
    id: 10,
    img_path: "/images/camphor.jpg",
    title: "कपूर (Camphor)",
    options: {
      price: [10],
    },
    unit: PACKET,
    unit_quantity: 1,
  },
  {
    id: 11,
    img_path: "/images/curd.avif",
    title: "दहीं (Curd)",
    options: {
      price: [12],
    },
    unit: GM,
    unit_quantity: 100,
  },
  {
    id: 12,
    img_path: "/images/milk.avif",
    title: "देशी गाय का दूध (Local Cow Milk)",
    options: {
      price: [40],
    },
    unit: MILLI_LITER,
    unit_quantity: 500,
  },
  {
    id: 13,
    img_path: "/images/ghee.png",
    title: "घी (Clarified Butter)",
    options: {
      price: [99],
    },
    unit: GM,
    unit_quantity: 100,
  },
  {
    id: 14,
    img_path: "/images/cotton_wicks.jpg",
    title: "रुई की बत्ती (Cotton Wicks)",
    options: {
      price: [5],
    },
    unit: PACKET,
    unit_quantity: 1,
  },
  {
    id: 15,
    img_path: "https://drive.google.com/thumbnail?id=1mWBwih1rByv-bkpK6kQgZJL0X1XlC145",
    title: "वीरम चंदन गोल्ड धूप 50 ग्राम (Veeram Chandan Gold Dhoop 50g)",
    options: {
      price: [40],
    },
    unit: PACKET,
    unit_quantity: 1,
  },
  {
    id: 16,
    img_path: "https://drive.google.com/thumbnail?id=1IlO_9PflE4RkGJYnyjWbcKd0f93kky4e",
    title: "वीरम फ्रेश मोगरा धूप 50 ग्राम (Veeram Fresh Mogra Dhoop 50g)",
    options: {
      price: [40],
    },
    unit: PACKET,
    unit_quantity: 1,
  },
  {
    id: 17,
    img_path: "https://drive.google.com/thumbnail?id=14pQ3pR78uSeVNXah78ZSVz3TGZ_x5wSj",
    title: "वीरम रोज़ ब्लॉसम धूप 50 ग्राम (Veeram Rose Blossom Dhoop 50g)",
    options: {
      price: [40],
    },
    unit: PACKET,
    unit_quantity: 1,
  },
  {
    id: 18,
    img_path: "/images/dhurva.jpeg",
    title: "दूर्वा (Durva)",
    options: {
      price: [10],
    },
    unit: BUNDLE,
    unit_quantity: 1,
  },
  {
    id: 19,
    img_path: "/images/elaichi.avif",
    title: "इलायची (Elaichi)",
    options: {
      price: [1],
    },
    unit: PCS,
    unit_quantity: 1,
  },
  {
    id: 20,
    img_path: "/images/haldi.avif",
    title: "हल्दी (Haldi)",
    options: {
      price: [15],
    },
    unit: GM,
    unit_quantity: 50,
  },
  {
    id: 21,
    img_path: "https://drive.google.com/thumbnail?id=1gW5JEwVpT-6RhkrQCHokZ8Or3zA-By3F",
    title: "उपला (Cow dung cake)",
    options: {
      price: [5],
    },
    unit: PCS,
    unit_quantity: 1,
  },
  {
    id: 22,
    img_path: "https://drive.google.com/thumbnail?id=1ihn-iH2QLsS0aPq7-2TPjj9pUuoEr9O1",
    title: "गंगा जल (Ganges Water)",
    options: {
      price: [10,40],
    },
    unit: PCS,
    unit_quantity: 1,
  },
  {
    id: 23,
    img_path: "/images/kush.jpg",
    title: "कुश (Kush)",
    options: {
      price: [10],
    },
    unit: PCS,
    unit_quantity: 1,
  },
  {
    id: 24,
    img_path: "https://drive.google.com/thumbnail?id=14YBJoiWmDqe29fuUueBQp8U8nEWNzWSs",
    title: "कलेवा(मौली) (Kaleva)",
    options: {
      price: [20],
    },
    unit: PCS,
    unit_quantity: 4,
  },
  {
    id: 25,
    img_path: "https://drive.google.com/thumbnail?id=1UJmhgu0jtTqr2q2zG45NAmdmEaaYn-6O",
    title: "चंदन गीला धूप (Chandan Wet Dhoop)",
    options: {
      price: [30],
    },
    unit: PCS,
    unit_quantity: 1,
  },
  {
    id: 26,
    img_path: "images/dhoop/gilla/guggal.jpg",
    title: "गुग्गल गीला धूप (Guggal Wet Dhoop)",
    options: {
      price: [30],
    },
    unit: PCS,
    unit_quantity: 1,
  },
  {
    id: 27,
    img_path: "https://drive.google.com/thumbnail?id=1HNuC2Ftdp8NhVVscAu5usXXHNqchpuI8",
    title: "गुलाब गीला धूप (Gulab Wet Dhoop)",
    options: {
      price: [30],
    },
    unit: PCS,
    unit_quantity: 1,
  },
  {
    id: 28,
    img_path: "https://drive.google.com/thumbnail?id=1P7TVQ2RWKVuxO-IjeqoTeD1Xc9VDonD3",
    title: "मोगरा गीला धूप (Mogra Wet Dhoop)",
    options: {
      price: [30],
    },
    unit: PCS,
    unit_quantity: 1,
  },
  {
    id: 29,
    img_path: "https://drive.google.com/thumbnail?id=1Ad936oxWHstaHzuE6RCxBiHYxrWx79AL",
    title: "महाकालेश्वर साम्ब्रानी धूप (Mahakaleshwar Sambrani Dhoop)",
    options: {
      price: [80],
    },
    unit: PCS,
    unit_quantity: 1,
  },
  {
    id: 30,
    img_path: "https://drive.google.com/thumbnail?id=1Ye0FRDJ1r4KLiQeR8d6Ef49WjNY2u9G7",
    title: "जय जगन्‍नाथ साम्ब्रानी धूप (Jai Jagannath Sambrani Dhoop)",
    options: {
      price: [100],
    },
    unit: PCS,
    unit_quantity: 1,
  }
];
