import { useContext } from "react";
import { UserProfileContext } from "../contexts/user-profile-context";
import { RUPEE } from "../constants/unicode-code-const";
import { ModalContext } from "../contexts/modal-context";
import { useTranslation } from "react-i18next";
import {
  CONTACT_TO_ORDER_TEXT,
  EMAIL,
  MOBILE_NUMBER,
  NO_ITEMS_YET_TEXT,
  ORDER_TEXT,
  POOJA_VED_HINDUISM_TEXT,
} from "../constants/transaltion-keys";

function Cart() {
  const { userProfileState } = useContext(UserProfileContext);
  const { setModalState } = useContext(ModalContext);
  const { t } = useTranslation();

  const getOptions = (item) => {
    return `${RUPEE} ${item.price}`;
  };

  const onOrderClick = () => {
    setModalState((prev) => {
      return {
        ...prev,
        isOpen: true,
        title: t(ORDER_TEXT),
        bodyFunc: null,
        hideFooter:true,
        body: (
          <section className=" d-flex justify-content-center align-items-center text-center">
            <div className="container">
              <p>{t(POOJA_VED_HINDUISM_TEXT)}</p>
              <p>{t(CONTACT_TO_ORDER_TEXT)}</p>
              <p>{t(MOBILE_NUMBER)} : 8879563646 / 8828145045 </p>
              <p>{t(EMAIL)}: asgupta2141@gmail.com</p>
            </div>
          </section>
        ),
        // bodyFunc: getModalBodyContent,
      };
    });
  };

  return (
    <div className="container vh-100">
      <div className="text-end">
        <button
          type="button"
          className="btn btn-primary mb-2 mt-2"
          onClick={onOrderClick}
        >
          {t(ORDER_TEXT)}
        </button>
      </div>
      <div className="accordion" id="cartModalCollapse">
        {userProfileState?.poojaList?.map((pooja, index) => {
          return (
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed d-flex justify-content-between"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse${index}`}
                  aria-controls="collapseOne"
                >
                  <span className="me-auto">{pooja?.name}</span>
                </button>
              </h2>
              <div
                id={`collapse${index}`}
                className="accordion-collapse collapse"
                data-bs-parent="#cartModalCollapse"
              >
                <div className="accordion-body">
                  {pooja?.items?.length ? (
                    <>
                      <ol className="list-group list-group-numbered">
                        {pooja?.items?.map((item) => {
                          return (
                            <li className="list-group-item d-flex border-0">
                              <div className="d-flex justify-content-between align-items-center flex-grow-1">
                                <span>{item?.title}</span>
                                {getOptions(item)}
                              </div>
                            </li>
                          );
                        })}
                      </ol>
                    </>
                  ) : (
                    t(NO_ITEMS_YET_TEXT)
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Cart;
