import { useContext, useEffect, useState } from "react";
import "./goods-items.css";
import { UserProfileContext } from "../../contexts/user-profile-context";
import { setPoojaList } from "../../services/local-storage";
import { ToastContext } from "../../contexts/toaster-context";
function GoodsItem({ goodItem }) {
  const [state, setState] = useState({
    selectedOptions: { price: null },
    poojaName: "",
    testName: "",
  });
  const { userProfileState, setUserProfileState } =
    useContext(UserProfileContext);
  const { addToast } = useContext(ToastContext);
  useEffect(() => {
    setState((prev) => {
      prev.selectedOptions.price = goodItem.options.price[0];
      return { ...prev };
    });
  }, [goodItem]);

  function setPricing(value) {
    setState((prev) => {
      prev.selectedOptions.price = Number(value);
      return { ...prev };
    });
  }

  const addPoojaListClickHandler = (goodItem) => {
    const selectedPooja = userProfileState.poojaList.find(
      (pooja) => pooja.id === Number(userProfileState.selectedPooja)
    );
    if (!selectedPooja.items) {
      selectedPooja["items"] = [];
    }
    selectedPooja.items.push({
      ...goodItem,
      ...(state?.selectedOptions || {}),
      sel_item_id: +new Date(),
    });
    setPoojaList(userProfileState?.poojaList);
    setUserProfileState((prev) => ({ ...prev }));
    addToast({
      id: +new Date(),
      body: `${goodItem.title} added to ${selectedPooja?.name}.`,
    });
  };

  function getUnitQuantity(unitQuantity) {
    if (unitQuantity && unitQuantity > 1) {
      return unitQuantity;
    }
  }

  return (
    <div
      key={goodItem.id}
      className="card shadow mb-2"
      style={{ width: "20rem" }}
    >
      <img
        src={goodItem.img_path}
        className="card-img-top object-fit-fill"
        alt={goodItem.title}
      />
      <div className="card-body">
        <h5 className="card-title">{goodItem.title}</h5>
        <p className="card-text">
          {goodItem.options?.price.map((price, index) => (
            <span
              className={`badge goods-items-badge ${
                state.selectedOptions.price === price
                  ? "text-bg-primary"
                  : "text-bg-secondary"
              } me-2`}
              onClick={setPricing.bind(null, price)}
              key={index}
            >
              <input
                className="goods-items-radio"
                type="radio"
                name={`goods_${goodItem.id}`}
                value={price}
                checked={state.selectedOptions.price === price}
              />
              &#8377; {price} / {getUnitQuantity(goodItem?.unit_quantity)}{" "}
              {goodItem.unit}
            </span>
          ))}
          <i
            className="fas fa-solid fa-cart-plus text-success cursor-pointer"
            onClick={() => addPoojaListClickHandler(goodItem)}
          ></i>
        </p>
      </div>
    </div>
  );
}

export default GoodsItem;
