import { useTranslation } from "react-i18next";
import Goods from "../components/goods-list";
import {
  CONTACT_TEXT,
  EMAIL,
  HEADER_TITLE,
  MOBILE_NUMBER,
  POOJA_GOODS_USE,
  VED_INSPIRATION_POOJA,
  VED_YAJYA_GOD_HAPPINESS,
  VED_YAJYA_WORK,
  YAJNA_OUR_PARTICIPATION,
} from "../constants/transaltion-keys";

function Home() {
  const { t } = useTranslation();
  return (
    <>
      <section className="hero d-flex justify-content-center align-items-center text-center py-5">
        <div className="container">
          <h1>{t(HEADER_TITLE)}</h1>
          <p>{t(VED_YAJYA_GOD_HAPPINESS)}</p>
          <p>{t(VED_INSPIRATION_POOJA)}</p>
          <p>{t(VED_YAJYA_WORK)}</p>
          <p>{t(POOJA_GOODS_USE)}</p>
        </div>
      </section>
      <Goods />
      <section className=" d-flex justify-content-center align-items-center text-center py-5">
        <div className="container">
          <p>{t(YAJNA_OUR_PARTICIPATION)}</p>
          <p>{t(CONTACT_TEXT)}</p>
          <p>{t(MOBILE_NUMBER)} : 8879563646 / 8828145045 </p>
          <p>{t(EMAIL)}: asgupta2141@gmail.com</p>
        </div>
      </section>
    </>
  );
}

export default Home;
