import { useContext } from "react";
import { ModalContext } from "../../contexts/modal-context";

function Modal() {
  const { modalState, setModalState } = useContext(ModalContext);

  if (!modalState?.isOpen) return null; // Early return if modal is not open
  function onCloseClick() {
    modalState?.closeClickHandler?.call(null);
    setModalState((prev) => {
      return { ...prev, isOpen: false };
    });
  }

  function onConfirmClick() {
    modalState?.confirmClickHandler?.call(null);
    setModalState((prev) => {
      return { ...prev, isOpen: false };
    });
  }

  return (
    <div
      className={`modal modal-lg fade ${modalState?.isOpen ? "show" : "hide"}`}
      tabindex="-1"
      data-bs-backdrop="static"
      style={{ display: modalState?.isOpen ? "block" : "none" }}
      id="myModal"
      aria-hidden="true"
      aria-labelledby="myModalLabel"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content" style={{ zIndex: 1100 }}>
          <div className="modal-header">
            <h5 className="modal-title" id="myModalLabel">
              {modalState?.title}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onCloseClick}
            ></button>
          </div>
          <div className="modal-body">
            {modalState?.bodyFunc ? modalState?.bodyFunc() : modalState?.body}
          </div>
          {!modalState.hideFooter && (
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={onCloseClick}
              >
                {modalState?.clsBtnText}
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={onConfirmClick}
              >
                {modalState?.confirmBtnText}
              </button>
            </div>
          )}
        </div>
      </div>
      <div
        className={`modal-backdrop fade ${
          modalState?.isOpen ? "show" : "hide"
        }`}
      ></div>
    </div>
  );
}

export default Modal;
